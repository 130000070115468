import * as React from "react";
import Slider from "../components/slider"
import * as styles from "../styles/sass/layout/_fv.module.scss"

const Fv = () => {
  return (
    <div className={styles.fv}>
        <Slider />
    </div>
  );
};

export default Fv;
