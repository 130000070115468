import * as React from "react"
import Header from "../components/header"
import Fv from "../components/fv"
import AboutInstagram from "../components/aboutInstagram"
import Footer from "../components/footer"

const IndexPage = () => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <Fv />
        <AboutInstagram />
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default IndexPage

export const Head = () => <title>GoGo Island | TOP</title>
