import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/scss";
import "swiper/css/pagination";

import * as styles from "../styles/sass/components/_slider.module.scss";

import TopImg1 from "../images/pc_fv01.png";
import TopImg2 from "../images/pc_fv02.png";
import TopImg3 from "../images/pc_fv03.png";
import TopImg4 from "../images/pc_fv04.png";

import SpTopImg1 from "../images/sp_fv01.png";
import SpTopImg2 from "../images/sp_fv02.png";
import SpTopImg3 from "../images/sp_fv03.png";
import SpTopImg4 from "../images/sp_fv04.png";

const Slider = () => {
  return (
    <>
      <Swiper
        className={styles.swiper}
        modules={[Pagination, Autoplay]}
        pagination={{
          el: "#pagination",
        }}
        autoplay={{
          delay: 8500,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        <SwiperSlide>
          <picture>
            <source srcset={TopImg1} media="(min-width: 481px)" />
            <img src={SpTopImg1} alt="トップ画像" className={styles.swiper__top1} />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source srcset={TopImg2} media="(min-width: 481px)" />
            <img src={SpTopImg2} alt="トップ画像" className={styles.swiper__top2} />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source srcset={TopImg3} media="(min-width: 481px)" />
            <img src={SpTopImg3} alt="トップ画像" className={styles.swiper__top3} />
          </picture>
        </SwiperSlide>
        <SwiperSlide>
          <picture>
            <source srcset={TopImg4} media="(min-width: 481px)" />
            <img src={SpTopImg4} alt="トップ画像" className={styles.swiper__top4} />
          </picture>
        </SwiperSlide>
      </Swiper>
      <div id="pagination" className={styles.swiperPagination}></div>
    </>
  );
};

export default Slider;
